<template>
	<div>
		<v-card>
			<s-toolbar label="Resumen" dark color="#8e8f91"></s-toolbar>
			<v-container style="padding: 20px;">
				<v-row justify="center" style="background-color: #9EB8F6; font-size: 14px; font-weight: 600; border-radius: 10px;">
					<v-col cols="12">
						<b style="color: red">(*)</b> Pesos referenciales segun Peso Promedio Jaba previamente configurado. Una vez Ingresado KG en Albaran se actualizará automaticamente segun el PP Jaba Calculado
					</v-col>
				</v-row>
				<v-row>
					<v-col :cols="ViewTotal==true ? 4 : 12"><v-switch v-model="ViewTotal" @click="load()" label="Por Lote Prod."></v-switch></v-col>
					<v-col v-if="ViewTotal" cols="8"><s-text label="N° Lote de Producción" v-model="RcfID" @input="inputLote($event)" ></s-text></v-col>
					
					<v-col cols="12" lg="12">
						<v-row>
							<v-col cols="12" md="4" align-self="center">
								<h3>CALIBRADO</h3>
							</v-col>
							<v-spacer> </v-spacer>
							<v-col cols="12" md="4" v-if="!filter.TrnIDCal"><s-date v-model="filter.cDateInitialCal" label="Fecha Inicio"></s-date></v-col>
							<v-col cols="12" md="4"  v-if="!filter.TrnIDCal"><s-date v-model="filter.cDateFinCal" label="Fecha Fin"></s-date></v-col>
							<v-col cols="12" md="4"><s-turn-in-process :TypeArea="5" clearable v-model="filter.TrnIDCal"></s-turn-in-process></v-col>
						</v-row>

						<hr style="margin: 20px 0px">
						<v-row>
							<v-col
								cols="6"
								lg="6"
								v-for="item in Object.keys(itemsKgCalibrated)"
							>
								<v-row>
									<h3 class="mt-3 ml-6">{{ item }}</h3>
									<v-spacer> </v-spacer>
								</v-row>

								<v-row>
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-col lg="12" class="pt-0">
									<v-list
										v-for="d in itemsKgCalibrated[item]"
									>
										<v-list-item>
											<v-list-item-avatar>
												<v-btn
													small
													elevation="0"
													fab
													dark
													color="info"
												>
													<b
														><h2>
															{{
																d.LppCountParihuela
															}}
														</h2></b
													>
												</v-btn>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title>
													<h1>
														{{
															d.LppWeightAverageLot.toFixed(
																2
															)
														}} <b style="font-family: Courier; font-size: 18px;"> KG</b>
														
													</h1>
													<b style="color: blue"></b>
												</v-list-item-title>

												<v-list-item-subtitle v-if="ViewTotal">
													<b><h2>LP: {{ d.RcfID }}</h2></b>
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase;-webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b>
													
												</v-list-item-subtitle>
												<v-list-item-subtitle v-if="!ViewTotal">
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase;-webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b>
												</v-list-item-subtitle>
											</v-list-item-content>
											<!-- <v-btn
										x-small
										elevation="0"
										fab
										dark
										color="error"
									>
										<v-icon style="font-size: 20px !important">
											fas fa-times
										</v-icon>
									</v-btn> -->
										</v-list-item>
									</v-list>
								</v-col>
								
							</v-col>

							<!-- <v-col cols="12" v-if="!ViewTotal">
									<b>Leyenda: </b>
									<v-chip color="error" x-small>Abast. Empaque</v-chip><v-chip color="info" x-small>Sin Abastecer</v-chip>
							</v-col> -->

							<v-divider vertical></v-divider>
						</v-row>

						<v-row>

						<v-col cols="6"><b>Total KG:</b><h3 style="font-size: 26px">{{sumKGCal.toFixed(2)}}</h3></v-col>
						<v-col cols="6"><b>Total Jabas:</b><h3 style="font-size: 26px">{{sumJabasCal}}</h3></v-col>
						</v-row>
					</v-col>

					<!-- <v-col cols="12" lg="6">
						<v-row>
							<h3 class="mt-3 ml-6">EMPAQUE</h3>
							<v-spacer> </v-spacer>
							<v-col cols="3"  v-if="!filter.TrnIDPack"><s-date v-model="filter.cDateInitialPack" label="Fecha Inicio"></s-date></v-col>
							<v-col cols="3"  v-if="!filter.TrnIDPack"><s-date v-model="filter.cDateFinPack" label="Fecha Fin"></s-date></v-col>
							<v-col cols="3"><s-turn-in-process :TypeArea="2" clearable v-model="filter.TrnIDPack"></s-turn-in-process></v-col>
						</v-row>

						<v-row>
							<v-col class="pb-0">
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								v-for="item in Object.keys(itemsKgPacking)"
							>
								<v-row>
									<h3 class="mt-3 ml-6">{{ item }}</h3>
									<v-spacer> </v-spacer>
								</v-row>

								<v-row>
									<v-col class="pb-0">
										<v-divider></v-divider>
									</v-col>
								</v-row>

								<v-col lg="12" class="pt-0">
									<v-list v-for="d in itemsKgPacking[item]">
										<v-list-item>
											<v-list-item-avatar>
												<v-btn
													small
													elevation="0"
													fab
													dark
													color="warning"
												>
													<b
														><h2>
															{{
																d.SpdCountParihuela
															}}
														</h2></b
													>
												</v-btn>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title>
													<h1>
														{{
															d.SpdWeightAverageLot.toFixed(
																2
															)
														}}<b style="font-family: Courier; font-size: 18px;"> KG</b>
													
													</h1>
													<b style="color: blue"></b>
												</v-list-item-title>

												<v-list-item-subtitle v-if="ViewTotal">
													<b><h2>LP: {{ d.RcfID }}</h2></b>
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase;  -webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b>
												</v-list-item-subtitle>
												<v-list-item-subtitle v-if="!ViewTotal">
													<b><h2>{{ d.TypeCaliberName }}</h2></b>
													<b style="text-transform: uppercase; -webkit-text-stroke: medium">{{d.TypeCropName}} - {{d.VrtName}}</b>
												</v-list-item-subtitle>
											</v-list-item-content>

										</v-list-item>
									</v-list>
								</v-col>
								
							</v-col>
							
							<v-divider vertical></v-divider>
						</v-row>

						<v-col cols="12"><b>Total KG:</b><h3 style="font-size: 26px">{{sumKPack}}</h3></v-col>
						
					</v-col> -->

					
					

					<!-- <v-col cols="12" v-if="ViewTotal">
						<v-row justify="center">


						</v-row>
					</v-col> -->
				</v-row>

				<!-- <v-row justify="center">
					<v-col>
						<v-btn color="info" @click="openGraphView()">Ver Grafica</v-btn>
					</v-col>
				</v-row> -->
			</v-container>
		</v-card>


		<v-dialog v-if="openGraph" v-model="openGraph" fullscreen hide-overlay>
			    <v-card>
					<v-row style="margin:auto">

					
					<v-col>
						<!-- <iframe title="reporte kg por lote" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=5f9f3f06-c3fe-4d01-9ae7-0ce93cff06ca&autoAuth=true&ctid=0d5ba43a-4bb0-45fa-a392-da62bc671600&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXBhYXMtMS1zY3VzLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameborder="0" allowFullScreen="true"></iframe> -->
						<iframe title="Control de almacenes" width="100%" height="1020" src="https://app.powerbi.com/view?r=eyJrIjoiMzhmN2Q2OGUtNWMyZC00NDkwLTk4MTQtOTgxNjIzNzUxZTVlIiwidCI6IjBkNWJhNDNhLTRiYjAtNDVmYS1hMzkyLWRhNjJiYzY3MTYwMCJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
					</v-col>
					</v-row>
					</v-card>

		</v-dialog>
	</div>
</template>

<script>
	import _sResumeParihuela from "@/services/FreshProduction/PrfLotProductionCalibratedService.js";
	import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue"
	import signalr from "signalr";
	import axios from "axios";

	export default {
		components: {sTurnInProcess},
		data() {
			return {
				filter: {},
				itemsKgPacking: [],
				itemsKgCalibrated: [],
				ViewTotal: true,
				itemsGeneric: [],
				RcfID: null,
				sumKGCal : 0,
				sumJabasCal: 0,
				sumKPack: 0,
				openGraph:false,
				alert:true,
				info: null
			};
		},
		watch: {
			
			'filter.cDateInitialCal'(){
				this.load()
			},
			'filter.cDateFinCal'(){
				this.load()
			},
			'filter.cDateInitialPack'(){
				this.load()
			},
			'filter.cDateFinPack'(){
				this.load()
			},
			'filter.TrnIDCal'(){
				if(this.filter.TrnIDCal > 0){
					this.filter.cDateInitialCal = null
					this.filter.cDateFinCal = null
				}

				this.load()
			},
			
			'filter.TrnIDPack'(){
				if(this.filter.TrnIDPack > 0){
					this.filter.cDateInitialPack = null
					this.filter.cDateFinPack = null
				}

				this.load()
			}

		},
		methods: {
			load() {
				var view;
				if(this.ViewTotal){
					view = 1
				}else{
					view = 0
				}

				_sResumeParihuela
					.GetKgCalibrated({TrnIDCal: this.filter.TrnIDCal, ViewTotal:view, cDateInitialCal: this.filter.cDateInitialCal, cDateFinCal: this.filter.cDateFinCal, RcfID: this.RcfID}, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {

							let itemsCalibra = resp.data;
							this.itemsKgCalibrated = _.groupBy(
								itemsCalibra,
								"TypeDestinyName"
							);
						this.isSumCal()
							

						}
					});

				_sResumeParihuela
					.GetKgPacking({TrnIDPack: this.filter.TrnIDPack ,ViewTotal: view, cDateInitialPack: this.filter.cDateInitialPack, cDateFinPack: this.filter.cDateFinPack, RcfID: this.RcfID}, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							let itemsPack = resp.data;
							this.itemsKgPacking = _.groupBy(
								itemsPack,
								"TypeDestinyName"
							);

							this.isSumPack()
						}
					});

					

					
			},

			isSumPack(){
				/* let itemsPack = []
				for (let prop in this.itemsKgPacking)
        			itemsPack.push(...this.itemsKgPacking[prop]); */

					var sum  = 0
					
					for (let prop in this.itemsKgPacking) {
						this.itemsKgPacking[prop].map((i) => {
							
							sum += parseFloat(i.SpdWeightAverageLot)
							
						});
					}
					this.sumKPack = sum
					
					console.log(sum);

				
			},

			isSumCal(){
				/* let itemsPack = []
				for (let prop in this.itemsKgPacking)
        			itemsPack.push(...this.itemsKgPacking[prop]); */

					var sum  = 0
					var sumJabas = 0
					for (let prop in this.itemsKgCalibrated) {
						this.itemsKgCalibrated[prop].map((i) => {
							sum += parseFloat(i.LppWeightAverageLot)
							sumJabas += parseInt(i.LppCountParihuela)
						});
					}
					this.sumKGCal = sum
					this.sumJabasCal = sumJabas
					console.log(sum);

				
			},

			inputLote(RcfID){
				this.load()
			},

			openGraphView(){
				axios
				.get('https://app.powerbi.com/view?r=eyJrIjoiMzhmN2Q2OGUtNWMyZC00NDkwLTk4MTQtOTgxNjIzNzUxZTVlIiwidCI6IjBkNWJhNDNhLTRiYjAtNDVmYS1hMzkyLWRhNjJiYzY3MTYwMCJ9&pageName=ReportSection')

/* <iframe title="Control de almacenes" width="600" height="373.5" src="https://app.powerbi.com/view?r=eyJrIjoiMzhmN2Q2OGUtNWMyZC00NDkwLTk4MTQtOTgxNjIzNzUxZTVlIiwidCI6IjBkNWJhNDNhLTRiYjAtNDVmYS1hMzkyLWRhNjJiYzY3MTYwMCJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe> */

				.then(response => (
					this.info = response
				))
				console.log("ddddddddddddddddddddddddddd",this.info);
				this.openGraph = true
			}
		},
		created() {
			this.load();
			this.isSumCal()
			this.isSumPack()
		},

		
		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendMessageSendParihuela = group => {
					this.load();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "updateWaitingsendparihuela");
				});
			});
		},
	};
</script>

<style>
</style>